
import React from 'react';
import './ourteam.css';

class BATeam extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: 'hello'
        }
    }

    render() {
        return (
            <div className="ourteam-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-lg-10 col-sm-12 col-xs-12 center-content">
                            <div className="row">
                                <div className="col-md-8 col-lg-8 col-sm-10 col-xs-10 center-content">
                                    <h2>Our Core Team</h2>
                                    <p>Bytesarray established with a passinated core team to deliver good services.</p>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <img src={process.env.PUBLIC_URL+"/images/me.jpg"} alt="" className="team-member-photo"/>
                                    <h5 className="team-member-name">Jagadeesh Patta</h5>
                                    <h6 className="team-member-designation">Technical Architect</h6>
                                </div>
                                <div className="col-md-4">
                                    <img src={process.env.PUBLIC_URL+"/images/sai.jpg"} alt="" className="team-member-photo"/>
                                    <h5 className="team-member-name">Sairam Kalluri</h5>
                                    <h6 className="team-member-designation">Sr.UX Designer</h6>
                                </div>
                                <div className="col-md-4">
                                    <img src={process.env.PUBLIC_URL+"/images/nisar.jpg"} alt="" className="team-member-photo"/>
                                    <h5 className="team-member-name">Nisar Md</h5>
                                    <h6 className="team-member-designation">Head of Operations</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BATeam;