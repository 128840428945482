
import React from 'react';
import './copyright.css';

class BACopyright extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: 'copyright'
        }
    }

    render() {
        return (
            <div className="copyright-container">
                <div className="container">
                    <p className="copyright">bytesarray software services © 2020</p>
                </div>
            </div>
        );
    }
}

export default BACopyright;