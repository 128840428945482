
import React from 'react';
import './services.css';

class BAServices extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: 'services'
        }
    }

    render() {
        return (
            <div className="services-container">
                <div className="container">
                    <div className="row">
                        <div className="title text-align-center">
                            <h2>What Do We Offer</h2>
                            <p className="col-lg-7 col-md-8 col-sm-10 col-xs-7">We are having differnet bytes of services for differnet levels of customers. And every byte has its own isolation of service. </p>
                            <div className="divider"></div>
                        </div>
                    </div>   
                    <div className="row">
                        <div className="col-md-4">
                            <div class="card">
                                <img src={process.env.PUBLIC_URL+"/images/ux.jpg"} className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title">UI / UX Byte</h5>
                                    <p className="card-text">This byte has unique identity to provides all levels of creative user experience and user interface to our clients.</p>
                                    <a href="#" className="btn btn-primary btn-learn-more">LEARN MORE</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <img src={process.env.PUBLIC_URL+"/images/web.jpg"} className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title">Mobile and Web Bytes</h5>
                                    <p className="card-text">Here we are providing solutions for enterprise and non-enterpirse level in both mobile and dynamic web platforms. </p>
                                    <a href="#" className="btn btn-primary btn-learn-more">LEARN MORE</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <img src={process.env.PUBLIC_URL+"/images/digitalmarketing.png"} className="card-img-top" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title">Digital Marketing Byte</h5>
                                    <p className="card-text">This byte mostly coming to the focus once the solution was build by no of bytes. And it will shows the solution identity. </p>
                                    <a href="#" className="btn btn-primary btn-learn-more">LEARN MORE</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BAServices;