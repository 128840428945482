
import React from 'react';
import './topnavbar.css';


class BANavbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stickyClass: "navigation navbar fixed-top",
            brandName: 'navbar-brand',
            navLink: 'nav-link'
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        let scrollTop = window.pageYOffset;
        if (scrollTop > 25) {
            this.setState({
                stickyClass: "navigation navbar fixed-top sticky-header",
                brandName: 'navbar-brand color-blue',
                navLink: 'nav-link color-black'
            })
        } else {
            this.setState({
                stickyClass: "navigation navbar fixed-top",
                brandName: 'navbar-brand',
                navLink: 'nav-link'
            })
        }
    }

    render() {
        return (  
            <header className={this.state.stickyClass}>  
                <div className="container">        
                    <nav className="navbar navbar-expand-lg navbar-light nav-fill w-100">
                        <a className={this.state.brandName} href="#">bytesarray</a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className={this.state.navLink} href="#">HOME</a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.state.navLink} href="#">ABOUT US</a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.state.navLink} href="#">SERVICES</a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.state.navLink} href="#">TEAM</a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.state.navLink} href="#">CONTACT</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}

export default BANavbar;