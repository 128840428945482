
import React from 'react';
import './customerreview.css';


class BAReview extends React.Component {

    render() {

        return (
            <div className="review-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-10 col-xs-10 col-lg-8 text-center">
                            <h2>Our Client Reviews</h2>
                            <p>Based on our efforts we delivered good no of projects. So that we have received good no of appreciations from our client. These will encourage our team and achieve few more milestones in our roadmap.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BAReview;