
import React from 'react';
import './contact.css';

class BAContact extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: 'name'
        }
    }

    render() {
        return (
            <div className="contact-conainer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12 center-text">
                            <h3>Get In Touch</h3>
                            <p>We are available at here to respond anytime. If any further queries please call us at given mobile number or hit an email to us.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <h4>Contact Details</h4>
                            <p><i class="fa fa-home" aria-hidden="true"></i> Road #02, Banjara Hills, Hyderabad, Telangana, India</p>
                            <p><i class="fa fa-volume-control-phone" aria-hidden="true"></i> Phone: +91 73311 37727</p>
                            <p><i class="fa fa-envelope" aria-hidden="true"></i> Email: contact@bytesarray.com</p>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                        <form>
                            <div class="form-group">
                                <input type="name" class="form-control" id="exampleInputName" aria-describedby="name" placeholder="Your Name"/>
                            </div>
                            <div class="form-group">
                                <input type="eamil" class="form-control" id="exampleInputEmail" placeholder="Your Email"/>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" id="exampleInputSubject" placeholder="Subject"/>
                            </div>
                            <div class="form-group">
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message"></textarea>
                            </div>
                            <button type="submit" class="btn btn-primary btn-block">SUBMIT</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BAContact;