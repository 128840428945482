
import React from 'react';
import Slider from 'react-animated-slider';
import "react-animated-slider/build/horizontal.css";
import './revslider.css';


class BASlider extends React.Component {

    constructor(props) {
        super(props);
        this.slides = [
            {
                title: 'Inspiring UI Designs',
                description:
                    'As a byte of bytesarray. we are happy to serve very attractive and interactive user interface and user experiences.',
                button: 'Read More',
                image: `${process.env.PUBLIC_URL}/images/two.jpg`
            },
            {
                title: 'Enterprise Mobile and Web Solutions',
                description:
                    'The next byte of bytesarray is an enterprise level mobile and dynamic web solution provieder.',
                button: 'Read More',
                image: `${process.env.PUBLIC_URL}/images/one.jpg`
            },
            {
                title: 'End-To-End SEO Optimization',
                description:
                    'This is the byte of bytesarray is providing Top level SEO services in the enterprise and non-enterprise industry.',
                button: 'Read More',
                image: `${process.env.PUBLIC_URL}/images/three.jpg`
            }
        ];
    }

    render() {

        return (
            <Slider className="slider-wrapper" autoplay="5000" duration="3000">
                {this.slides.map((item, index) => (
                    <div
                    key={index}
                    className="slider-content"
                    style={{ background: `url('${item.image}') no-repeat center center` }}
                    >
                    <div className="inner">
                        <h1>{item.title}</h1>
                        <p>{item.description}</p>
                        <button>{item.button}</button>
                    </div>
                    </div>
                ))}
            </Slider>
        );
    }
}

export default BASlider;