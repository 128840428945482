
import React from 'react';
import './about.css';


class BAAbout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: 'hello'
        }
    }

    render() {
        return (
            <div className="about-container">
                <div className="container">
                    <div className="row">
                        <div className="title text-align-center">
                            <h2>We Are BytesArray</h2>
                            <p className="col-lg-7 col-md-8 col-sm-10 col-xs-7">We are the service providers for the enterprise mobile and web based applications. We are guarented to give hundered pecent reliable solutions to our customers.</p>
                            <div className="divider"></div>
                        </div>
                    </div>                    
                    <div className="row padding-top-50">
                        <div className="col-md-6">
                            <img className="full-width-and-height vertical-middle" src={process.env.PUBLIC_URL+"/images/weare.jpg"} alt="We are bytesarray"/>
                        </div>
                        <div className="col-md-6">
                            <ul className="weare-list-items vertical-middle">
                                <li><i className="fa fa-check-square-o" aria-hidden="true"></i>  &nbsp; We are dedicated at work to meet our customer's goals.</li>
                                <li><i className="fa fa-check-square-o" aria-hidden="true"></i> &nbsp; We are experts in UI/UX desins and development.</li>
                                <li><i className="fa fa-check-square-o" aria-hidden="true"></i> &nbsp; We are experts in delivering dynamic web and mobile solutions.</li>
                                <li><i className="fa fa-check-square-o" aria-hidden="true"></i> &nbsp; We are experts in SEO and Digital Marketing.</li>
                                <li><i className="fa fa-check-square-o" aria-hidden="true"></i> &nbsp; Bytesarray is a team of experts.</li>
                                <li><i className="fa fa-check-square-o" aria-hidden="true"></i> &nbsp; Finally bytesarray is not a company. It's a stream of solutions.</li>
                            </ul>
                            <button type="button" className="btn btn-primary btn-weare-more">Learn More</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BAAbout;