import React from 'react';
import './App.css';
import BANavbar from './topnavbar/topnavbar';
import BASlider from './revslider/revslider';
import BAAbout from './about/about';
import BAServices from './services/services';
import BAReview from './customerreviews/customerreview';
import BATeam from './ourteam/ourteam';
import BAContact from './contact/contact';
import BACopyright from './copyright/copyright';

class App extends React.Component {

    render() {
        return (
          <div className="App">
            <BANavbar></BANavbar>
            <BASlider></BASlider>
            <BAAbout></BAAbout>
            <BAServices></BAServices>
            <BAReview></BAReview>
            <BATeam></BATeam>
            <BAContact></BAContact>
            <BACopyright></BACopyright>
          </div>
        );
    }
}

export default App;
